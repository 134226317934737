import 'babel-polyfill';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route} from "react-router-dom";
import queryString from 'query-string';

//コンポーネント読み込み
import Parent from './components/Parent';

ReactDOM.render(
  <Router>
    <Route render={ (props) => 
      <Parent
          qs={queryString.parse(props.location.search)}
      />
    }/>
  </Router>, 
document.getElementById("contents"));