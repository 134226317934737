import React, {Component, Fragment} from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';

const initialState = {
}

class SnsRelationSelect extends Component {
  state = initialState;

  componentDidMount() {

    // セッションストレージにSNSログイン情報が存在しない場合、システムエラー画面へ遷移する
//    if (!sessionStorage.getItem('SNSLOGIN')) {
//      this.props.history.push({
//        pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`
//      });
//    }
//    else {
      // GA計測用データレイヤースクリプトを追加する
      window.addDataLayerScript('jmbauth_sns_start');
//    }
  }

  // ［連携する］ボタン押下時処理
  snsRelation = () => {
    
    // SNS認証紐付け確認画面に遷移する
    window.location.href = `${process.env.REACT_APP_CONTEXT_PATH}/sns_relation`;
  }

  render() {

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // RP別デザインを取得する
    let designI01;
    // デザイン定義ファイルを読み込み
    let designJson = require('../jsonFile/design.json');

    // 画面、項目ID、デザイン値に該当するデザインファイルを読み込む
    Object.keys(designJson.snsRelationSelect.I01).forEach(function(data) {
      if (data === sessionStorage.getItem('design')) {
        designI01 = require('./design/' + designJson.snsRelationSelect.I01[data]);
      }    
    });
    
    return (
      <section className="section">

        {/* ブラウザタイトル */}
        <Helmet
          title={messageList.snsRelationSelect.M001_browserTitle} >
        </Helmet>

        {/* 画面タイトル表示部 */}
        <div className="hdg-l1">
          <h1 className="hdg-l1_txt">{messageList.snsRelationSelect.M002_pageTitle}</h1>
        </div>

        {/* ガイド表示部 */}
        <p>{messageList.snsRelationSelect.M003_guideMessage001}</p>
        <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/snsid/" className="link-txt _other" target="otherwindow">
          <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.snsRelationSelect.M005_newWindowIcon} className="icon-blank"/>
            {messageList.snsRelationSelect.M004_guideMessage002}</a>

        <div className="panel-form other-id-link-wrap">
          {/* JALカード会員以外の方 */}
          {!designI01 &&
          <div className="panel-form-inner dis-fl">
            <div className="panel-form-inner_item dis-fl-item">
              <div className="dis-fl-item_contents">
                <h3>{messageList.snsRelationSelect.M006_memberText001}</h3>
                <div className="btn-wrap">
                  <div>
                  <button type="button" className="btn non-arrow" onClick={this.snsRelation}>{messageList.snsRelationSelect.M007_link}</button>
                  </div>
                  <div>
                    <a href="https://www.jal.com/index.html" className="btn-back">{messageList.snsRelationSelect.M008_back}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel-form-inner_item dis-fl-item">
              <div className="dis-fl-item_contents">
                <h3>{messageList.snsRelationSelect.M009_nonMemberText001}<br></br>{messageList.snsRelationSelect.M010_nonMemberText002}</h3>
                <p>{messageList.snsRelationSelect.M011_nonMemberText003}</p>
                {(sessionStorage.getItem("lang") !== 'ja') &&
                  <p className="left">{messageList.snsRelationSelect.M019_nonMemberText009}</p>
                }
                <div className="btn-wrap not-yet-btn_wrap">
                  <div><a href="https://www.jal.co.jp/jp/ja/jmb/index09.html" className="btn r-arrow btn-WH">
                    {messageList.snsRelationSelect.M012_nonMemberText004}</a></div>
                  <div><a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/join/world/" className="btn r-arrow btn-WH">
                    {messageList.snsRelationSelect.M013_nonMemberText005}</a></div>
                </div>
                {(sessionStorage.getItem("lang") === 'ja') ?
                  <div lang="en">
                    <p className="mgt-32">{messageList.snsRelationSelect.M014_nonMemberText006}</p>
                    <div className="btn-wrap not-yet-btn_wrap">
                      <div><a href="https://www.jal.co.jp/jp/en/jmb/index09.html" className="btn r-arrow btn-WH">
                        {messageList.snsRelationSelect.M015_nonMemberText007}</a></div>
                      <div><a href="https://www.jal.co.jp/jp/en/jmb/jmb-login/join/world/" className="btn r-arrow btn-WH">
                        {messageList.snsRelationSelect.M016_nonMemberText008}</a></div>
                    </div>
                  </div> :
                  <Fragment>
                    <p className="mgt-32">{messageList.snsRelationSelect.M014_nonMemberText006}</p>
                    <p className="left">{messageList.snsRelationSelect.M020_nonMemberText010}</p>
                    <div className="btn-wrap not-yet-btn_wrap">
                      <div><a href="https://www.jal.co.jp/jp/en/jmb/index09.html" className="btn r-arrow btn-WH">
                        {messageList.snsRelationSelect.M015_nonMemberText007}</a></div>
                      <div><a href="https://www.jal.co.jp/jp/en/jmb/jmb-login/join/world/" className="btn r-arrow btn-WH">
                        {messageList.snsRelationSelect.M016_nonMemberText008}</a></div>
                    </div>
                  </Fragment>
                }             
              </div>
            </div>
          </div>}

          {/* JALカード会員の方 */}
          {designI01 &&
          <div className="panel-form-inner">
            <div className="panel-form-inner_item">
              <div className="dis-fl-item_contents">
                <h3>{messageList.snsRelationSelect.M006_memberText002}</h3>
                <div className="btn-wrap btn-clm2-l">
                  <div>
                    <button type="button" className="btn non-arrow" onClick={this.snsRelation}>{messageList.snsRelationSelect.M007_link}</button>
                  </div>
                  <div>
                    <a href="https://www.jal.com/index.html" className="btn-back">{messageList.snsRelationSelect.M008_back}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>

        {/* Rトースターエリア */}
        {(designI01) && designI01.getDesign(sessionStorage.getItem('lang'))}

        {/* 案内表示部 */}
        <div className="hdg-guide">
          <h2 className="hdg-guide_txt">{messageList.snsRelationSelect.M017_notices}</h2>
        </div>
        <ul className="list">
          <li>{messageList.snsRelationSelect.M018_notice001}</li>
        </ul>
      </section>
    );
  }
}

export default withRouter(SnsRelationSelect)