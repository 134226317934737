import React, {Fragment } from 'react';
import Helmet from 'react-helmet';              

function getDesign() {
    return (
      <Fragment>
        <Helmet>
            <script type="text/javascript">{`
              var JALCOJP = Rtoaster.Cookie.get("JALCOJP");
              var memNo = Rtoaster.Cookie.get("memNo");
              var rt_update_cookie = ""
              
              if (JALCOJP && !memNo) {
                rt_update_cookie = Rtoaster.Cookie.get("JALCOJP");
              } else if (!JALCOJP && memNo) {
                rt_update_cookie = Rtoaster.Cookie.get("memNo");
              } else if (JALCOJP && memNo) {
              　rt_update_cookie = Rtoaster.Cookie.get("memNo");
              } else {
                
              };
              
              var rt_data = {
                user_id: rt_update_cookie
              };
            `}</script>

            <script type="text/javascript">{`!function(r,t,j,s){(j=r.createElement(t)).type="text/javascript",
            j.async=!0,j.charset="utf-8",j.src="//js.rtoaster.jp/RTA-b526-91dfccdc03ed/rt.js",
            (s=r.getElementsByTagName(t)[0]).parentNode.insertBefore(j,s)
            }(document,"script")`}</script>
        </Helmet>
        <div id="rt_y20_jmblogin"></div>
      </Fragment>
      
    );
}

export {getDesign}