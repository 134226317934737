import React, {Component} from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';

const initialState = {
  oldPwd: '',
  newPwd: '',
  reNewPwd: '',
  complete: false,
  errorMap: null,
  loading: false,
  showPwd: false
}

class PwdChange extends Component {
  state = initialState;

  componentDidMount() {

    // GA計測用データレイヤースクリプトを追加する
    window.addDataLayerScript('jmbauth_pwd_change');
  }

  // パスワード変更画面[変更]ボタン押下時処理
  pwdChange = ()  => {

    // アクションのレポート作成
    try{
      window.tsPlatform.drs.triggerActionEvent("password_reset_change").then((actionResponse) => {
        let actionToken = actionResponse.actionToken;
      });
    }catch(e){
    }

    const { oldPwd, newPwd, reNewPwd } = this.state;

    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // 画面入力項目のバリデーションチェックを行う
    if (oldPwd === '') {
      this.setState({ errorMap: { ERROR: messageList.pwdChange.M016_errorMessage001, errorId: ['oldPwd'] }, });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
      // 処理を終了する。
      return;
    }

    if (newPwd === '') {
      this.setState({ errorMap: { ERROR: messageList.pwdChange.M017_errorMessage002, errorId: ['newPwd'] }, });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
      // 処理を終了する。
      return;
    }

    if (newPwd !== reNewPwd) {
      this.setState({ errorMap: { ERROR: messageList.pwdChange.M018_errorMessage003, errorId: ['newPwd', 'reNewPwd'] }, newPwd: '', reNewPwd: ''
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
      // 処理を終了する。
      return;
    }

    // パスワード変更要求を行う
    try {
      // ローディング開始
      this.setState({ loading: true });     

      // 3Keyパスワード変更API  
      axios
        .put( process.env.REACT_APP_URL_CO_SC_API_PASSWORD_CHANGE,
          {
            newPassword: newPwd,
            oldPassword: oldPwd
          },  
          {headers: {'accept-language': window.changeLang(sessionStorage.getItem('lang')),
                     'Cache-Control': 'no-store',
                     'Pragma': 'no-cache'}}
        )
        .then( response => {
          // Cookie設定
          document.cookie = String(`JalAcc=${newPwd.length >= 8 ? 'True' : 'False'};domain=${process.env.REACT_APP_COOKIE_DOMAIN};path=${process.env.REACT_APP_COOKIE_PATH};`);
          this.setState({ errorMap: null, complete: true, loading: false });
          // GA計測用データレイヤースクリプトを追加する
          window.addDataLayerScript('jmbauth_pwd_change_completion');
        })
        .catch( error => {
          if ( error.response ) {
            if ( error.response.status === 400 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.pwdChange.M019_errorMessage004 },
                loading: false, oldPwd: '', newPwd: '', reNewPwd: ''
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            else if ( error.response.status === 401 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.pwdChange.M019_errorMessage004 },
                loading: false, oldPwd: '', newPwd: '', reNewPwd: ''
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            else if ( error.response.status === 500 ) {

              // エラー画面に遷移し、処理を終了する
              this.props.history.push({
                pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`
              });
            }
            else {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.pwdChange.M019_errorMessage004 },
                loading: false, oldPwd: '', newPwd: '', reNewPwd: ''
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
          }
          else {
            this.setState({ errorMap:
              { ERROR: messageList.pwdChange.M019_errorMessage004 },
              loading: false, oldPwd: '', newPwd: '', reNewPwd: ''
            });
            // エラーメッセージ表示領域に遷移する
            window.location="#JS_error";
          }
        })
    } catch ( err ) {
      console.error(err);
      this.setState({ errorMap:
        { ERROR: messageList.pwdChange.M019_errorMessage004 },
        loading: false, oldPwd: '', newPwd: '', reNewPwd: ''
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";

    } finally {
    }
  }

  // パスワード表示切り替え処理
  swhichPwdShow = e => {
    this.setState({ showPwd: e.currentTarget.checked });
  }

  handleChange = e => {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value.trim() });
  }

  // 画面をクローズする
  close = e => {
    window.open('about:blank','_self').close();
  }

  render() {
    
    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // パスワード変更完了画面表示
    if (this.state.complete){
      return (
        <section>
          <Helmet
            title={messageList.pwdChangeCompletion.M001_browserTitle} >
          </Helmet>

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.pwdChangeCompletion.M002_pageTitle}</h1>
          </div>

          {/* 操作ボタン表示部 */}
          <div className="btn-wrap">
            <button type="button" name="__dummy__" className="btn-back" onClick={this.close}>{messageList.pwdChangeCompletion.M003_close}</button>
          </div>

        </section>
      );
    }

    // パスワード変更画面表示
    else {
      return (
        <section>
          <Helmet
            title={messageList.pwdChange.M001_browserTitle} >
          </Helmet>

          { // Loadingモーダル表示
            this.state.loading &&
            <div id="loading" className="loading-modal"><img className="loading-img" src={`${process.env.PUBLIC_URL}/img/load.gif`} alt="loading"/></div>
          }

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.pwdChange.M002_pageTitle}</h1>
          </div>

          <div id="JS_error">
          { // エラーメッセージ表示
          (this.state.errorMap && this.state.errorMap.ERROR) &&
            <div className="panel-attention">
              <h2 className="panel-attention_hdg"><span className="panel-attention_icon"><img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.pwdChange.M003_errorIconText}/></span>{messageList.pwdChange.M004_errorBoxTitle}</h2>
              <p>{this.state.errorMap.ERROR}</p>
            </div>/* /panel-attention */
          }
          </div>

          <div className="panel-form">
            <div className="panel-form-inner">
              <div className="panel-form-inner_item">
                <form name="dummy" id="JS_jmbForm">

                  {/* 画面項目表示部 */}
                  {/* 現在のパスワード */}
                  <div className="panel-form-inner_parts">                    
                    <p className="form-label"><label htmlFor="LA_input-password-01">{messageList.pwdChange.M005_oldPassword}</label></p>
                    <input type={ this.state.showPwd ? "text" : "password"} id="LA_input-password-01" name="oldPwd"
                      className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('oldPwd') ? 
                      "form-txt js-form-validate-blank JS_jmbNo is-blank is-error" : "form-txt js-form-validate-blank JS_jmbNo is-blank"} 
                      maxLength="256" placeholder="" 
                      aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('oldPwd') ? "true" : "false"} 
                      value={this.state.oldPwd} onChange={this.handleChange}/>
                  </div>
                 
                  <div className="panel-form-inner_parts">

                    {/* 変更後のパスワード */}
                    <p className="form-label"><label htmlFor="LA_input-password-02">{messageList.pwdChange.M006_newPassword}</label></p>
                    <p>{messageList.pwdChange.M007_newPasswordType}</p>
                    <p className="form-table_txt mb-form-label">
                      <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/password/" className="link-txt _other" target="otherwindow">
                      <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.pwdChange.M008_aboutPasswordIcon} className="icon-blank"/>{messageList.pwdChange.M009_aboutPassword}</a>
                    </p>
                    <input type={ this.state.showPwd ? "text" : "password"} id="LA_input-password-02" name="newPwd"
                      className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('newPwd') ? 
                      "form-txt js-form-validate-blank JS_jmbNo is-blank is-error" : "form-txt js-form-validate-blank JS_jmbNo is-blank"}
                      maxLength="256" placeholder="" 
                      aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('newPwd') ? "true" : "false"} 
                      value={this.state.newPwd} onChange={this.handleChange}/>

                    {/* 変更後のパスワード再入力 */}
                    <p className="form-label"><label htmlFor="LA_input-password-03">{messageList.pwdChange.M010_reNewPassword}</label></p>
                    <input type={ this.state.showPwd ? "text" : "password"} id="LA_input-password-03" name="reNewPwd" 
                      className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('reNewPwd') ? 
                      "form-txt js-form-validate-blank JS_jmbPass is-blank is-error" : "form-txt js-form-validate-blank JS_jmbPass is-blank"} 
                      maxLength="256" placeholder="" 
                      aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('reNewPwd') ? "true" : "false"} 
                      value={this.state.reNewPwd} onChange={this.handleChange} />

                    {/* パスワードを表示する */}
                    <span className="form-checkbox _block">
                      <input type="checkbox" id="LA_form-checkbox-01" name="__dummy__" aria-controls="LA_input-password-01 LA_input-password-02" onChange={this.swhichPwdShow} />
                      <label htmlFor="LA_form-checkbox-01" className="form-checkbox_item is-blank">{messageList.pwdChange.M011_showPassword}</label>
                    </span>
                  </div>

                  {/* 操作ボタン表示部 */}
                  <div className="btn-wrap">
                    <button type="button" name="__dummy__" className="btn" onClick={this.pwdChange}>{messageList.pwdChange.M012_update}</button>
                  </div>

                </form>
              </div> {/* /panel-form-inner_item */}
            </div> {/* /panel-form-inner */}
          </div>

          <div className="hdg-guide">
            <h2 className="hdg-guide_txt">{messageList.pwdChange.M013_notices}</h2>
          </div>
          <ul className="list">           
            <li>{messageList.pwdChange.M014_notice001}</li>
          </ul>        
        </section>
      );
    }
  }
}

export default PwdChange