import React, { Component } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';

const initialState = {
  phonenumber: '',
  authCode: '',
  onetimeTid: '',
  dispKbn: 0,
  errorMap: null,
  loading: false
}

class MobilenumberChange extends Component {
  state = initialState;

  componentDidMount() {

    // GA計測用データレイヤースクリプトを追加する
    window.addDataLayerScript('jmbauth_otp_send_confirmation_code');

  }

  // 携帯電話番号登録変更開始画面[送信する]ボタン押下処理
  send = () => {

    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // バリデーションチェックを行う
    let phonenumber = document.getElementById('LA_input-tel').value;
    if (phonenumber === '') {
      this.setState({ errorMap: { ERROR: messageList.moblienumberChange.M017_errorMessage001, errorId: ['phonenumber'] } });
      return;
    }
    const otpMobilenumber = sessionStorage.getItem('OTPMOBILENUMBER');
    if (otpMobilenumber !== '' && otpMobilenumber === phonenumber) {
      this.setState({ errorMap: { ERROR: messageList.moblienumberChange.M018_errorMessage002, errorId: ['phonenumber'] } });
      return;
    }

    // 携帯電話番号登録・変更SMS送信要求を行う
    try {

      // ローディング開始
      this.setState({ loading: true });

      // 携帯電話番号登録・変更SMS送信要求API
      axios.post(process.env.REACT_APP_URL_CO_SC_API_MOBILENUMBER,
        {
          phonenumber: phonenumber
        },
        {
          headers: {
            'accept-language': window.changeLang(sessionStorage.getItem("lang")),
            'Cache-Control': 'no-store',
            'Pragma': 'no-cache'
          }
        }
      )
        .then(response => {

          this.setState({ errorMap: null, onetimeTid: response.data.onetimeTid, dispKbn: 1, loading: false, phonenumber: phonenumber, authCode:'' });
          // GA計測用データレイヤースクリプトを追加する
          window.addDataLayerScript('jmbauth_otp_input_confirmation_code');
          
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              this.setState({
                errorMap:
                  { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.moblienumberChange.M019_errorMessage003 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location = "#JS_error";
            }
            else if (error.response.status === 401) {
              this.setState({
                errorMap:
                  { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.moblienumberChange.M019_errorMessage003 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location = "#JS_error";
            }
            else if (error.response.status === 500) {

              // エラー画面に遷移し、処理を終了する
              this.props.history.push({
                pathname: `${process.env.REACT_APP_CONTEXT_PATH}/error`
              });
            }
            else {
              this.setState({
                errorMap:
                  { ERROR: messageList.moblienumberChange.M019_errorMessage003 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location = "#JS_error";
            }
          }
          else {
            this.setState({
              errorMap:
                { ERROR: messageList.moblienumberChange.M019_errorMessage003 },
              loading: false
            });
            // エラーメッセージ表示領域に遷移する
            window.location = "#JS_error";
          }
        });

    } catch (err) {
      console.error(err);
      this.setState({
        errorMap:
          { ERROR: messageList.moblienumberChange.M019_errorMessage003 },
        loading: false
      });
      // エラーメッセージ表示領域に遷移する
      window.location = "#JS_error";

    } finally {
    }
  }

  // 携帯電話番号登録変更画面[送信する]ボタン押下処理
  change = () => {

    // アクションのレポート作成
    try{
      window.tsPlatform.drs.triggerActionEvent("account_details_change_mobilenumberchange").then((actionResponse) => {
        let actionToken = actionResponse.actionToken;
      });
    }catch(e){
    }

    let messageList = JSON.parse(sessionStorage.getItem('messageList'));
    let authCode = document.getElementById('LA_input-code').value;
    if (authCode === '') {
      this.setState({ errorMap: { ERROR: messageList.moblienumberChangeSetting.M015_errorMessage001, errorId: ['authCode'] } });
      return;
    }

    // 携帯電話番号登録・変更要求を行う
    try {

      // ローディング開始
      this.setState({ loading: true });

      // 携帯電話番号登録・変更API
      axios.put(process.env.REACT_APP_URL_CO_SC_API_MOBILENUMBER_CHANGE,
        {
          onetimeTid: this.state.onetimeTid,
          authCode: authCode
        },
        {
          headers: {
            'accept-language': window.changeLang(sessionStorage.getItem("lang")),
            'Cache-Control': 'no-store',
            'Pragma': 'no-cache'
          }
        }
      )
        .then(response => {

          this.setState({ phonenumber: '', authCode: '', onetimeTid: '', errorMap: null, dispKbn: 2, loading: false });
          // GA計測用データレイヤースクリプトを追加する
          window.addDataLayerScript('jmbauth_otp_mobile_number_completion');
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              this.setState({
                errorMap:
                  { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.moblienumberChangeSetting.M016_errorMessage002 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location = "#JS_error";
            }
            else if (error.response.status === 401) {
              this.setState({
                errorMap:
                  { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.moblienumberChangeSetting.M016_errorMessage002 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location = "#JS_error";
            }
            else if (error.response.status === 403) {
              this.setState({
                errorMap:
                  { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.moblienumberChangeSetting.M016_errorMessage002 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location = "#JS_error";
            }
            else if (error.response.status === 500) {

              // エラー画面に遷移し、処理を終了する
              this.props.history.push({
                pathname: `${process.env.REACT_APP_CONTEXT_PATH}/error`
              });
            }
            else {
              this.setState({
                errorMap:
                  { ERROR: messageList.moblienumberChangeSetting.M016_errorMessage002 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location = "#JS_error";
            }
          }
          else {
            this.setState({
              errorMap:
                { ERROR: messageList.moblienumberChangeSetting.M016_errorMessage002 },
              loading: false
            });
            // エラーメッセージ表示領域に遷移する
            window.location = "#JS_error";
          }
        });

    } catch (err) {
      console.error(err);
      this.setState({
        errorMap:
          { ERROR: messageList.moblienumberChangeSetting.M016_errorMessage002 },
        loading: false
      });
      // エラーメッセージ表示領域に遷移する
      window.location = "#JS_error";

    } finally {
    }
  }

  // OTP通知先変更画面へ戻る処理
  beforeOtpChange = () => {
    window.location.href = `${process.env.REACT_APP_CONTEXT_PATH}/menu/otp_auth?design=` + sessionStorage.getItem('design') + "&lang=" + sessionStorage.getItem('lang');
  }

  beforeMobilenumberChange = () => {
    this.setState({ onetimeTid: '', dispKbn: 0, errorMap: null });
  }

  handleChange = e => {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value.trim() });
  }

  // Enterキー押下時処理
  handleKeyPress = e => {
    if(e.key === "Enter"){
      e.preventDefault();
      if(this.state.dispKbn === 0){
        this.send();
      }else if(this.state.dispKbn === 1){
        this.change();
      }
    }
  }

  componentDidUpdate(prevProps,prevState) {
    if (prevState.dispKbn !== this.state.dispKbn && this.state.dispKbn === 1){
      if ("OTPCredential" in window) {
        const ac = new AbortController();
        navigator.credentials
          .get({
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            this.setState({ authCode : otp.code });
          })
          .catch((err) => {
          });
      }
    }
  }

  render() {

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));
    
    const boxStyle = {
      visibility:"hidden",
      width:"1px",
      height:"1px"
    };

    // 携帯電話番号登録変更開始画面表示
    if (this.state.dispKbn === 0) {
      return (
        <section>
          <Helmet
            title={messageList.moblienumberChange.M001_browserTitle} >
          </Helmet>

          { // Loadingモーダル表示
            this.state.loading &&
            <div id="loading" className="loading-modal"><img className="loading-img" src={`${process.env.PUBLIC_URL}/img/load.gif`} alt="loading" /></div>
          }

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.moblienumberChange.M002_pageTitle}</h1>
            <h2 className="hdg-l1_txtSub">{messageList.moblienumberChange.M003_pageTitleSub}</h2>
          </div>

          <p>{messageList.moblienumberChange.M004_mobileNumberChangeText001}</p>
          <p>{messageList.moblienumberChange.M005_mobileNumberChangeText002}<br></br>
            {messageList.moblienumberChange.M006_mobileNumberChangeText003}</p>

          <div id="JS_error">
            { // エラーメッセージ表示
              (this.state.errorMap && this.state.errorMap.ERROR) &&
              <div className="panel-attention">
                <h2 className="panel-attention_hdg">
                  <span className="panel-attention_icon">
                    <img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.moblienumberChange.M007_errorIconText} />
                  </span>{messageList.moblienumberChange.M008_errorBoxTitle}
                </h2>
                <p>{this.state.errorMap.ERROR}</p>
              </div>/* /panel-attention */
            }
          </div>

          <div className="panel-form">
            <div className="panel-form-inner">
              <div className="panel-form-inner_item">
                <form name="dummy" id="JS_jmbForm">

                  <div className="panel-form-inner_parts">
                    <p className="form-label"><label htmlFor="LA_input-tel">{messageList.moblienumberChange.M009_mobileNumberChangeText004}</label></p>
                    <input type="tel" id="LA_input-tel" name="phonenumber"
                    className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('phonenumber') ? 
                    "form-txt js-form-validate-blank is-error JS_jmbTel is-blank" : "form-txt js-form-validate-blank JS_jmbTel is-blank"} 
                    maxLength="11" aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('phonenumber') ? "true" : "false"} 
                    value={this.state.phonenumber} onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}/>
                    <p className="mgt-16">{messageList.moblienumberChange.M010_mobileNumberChangeText005}</p>
                    <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/otp/" className="link-txt _other" target="otherwindow">
                      <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.moblienumberChange.M011_newWindowIcon} className="icon-blank" />{messageList.moblienumberChange.M012_otpPassword}</a>
                  </div>
                  <div className="btn-wrap">
                    <button type="button" name="__dummy__" className="btn" onClick={this.send}>{messageList.moblienumberChange.M013_send}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* 操作ボタン表示部 */}
          <div className="btn-wrap btn-clm2-l">
            <button type="button" className="btn-back" onClick={this.beforeOtpChange}>{messageList.moblienumberChange.M014_back}</button>
          </div>

          {/* 案内表示部 */}
          <div className="hdg-guide">
            <h2 className="hdg-guide_txt">{messageList.moblienumberChange.M015_notices}</h2>
          </div>
          <ul className="list">
            <li>{messageList.moblienumberChange.M016_notice001}</li>
          </ul>
        </section>
      );
    }

    // 携帯電話番号登録変更画面表示
    else if (this.state.dispKbn === 1) {
      return (
        <section>
          <Helmet
            title={messageList.moblienumberChangeSetting.M001_browserTitle} >
          </Helmet>

          { // Loadingモーダル表示
            this.state.loading &&
            <div id="loading" className="loading-modal"><img className="loading-img" src={`${process.env.PUBLIC_URL}/img/load.gif`} alt="loading" /></div>
          }

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.moblienumberChangeSetting.M002_pageTitle}</h1>
            <h2 className="hdg-l1_txtSub">{messageList.moblienumberChangeSetting.M003_pageTitleSub}</h2>
          </div>

          <p>{messageList.moblienumberChangeSetting.M004_mobileNumberChangeSettingText001}
          {this.state.phonenumber.substring(0, 3) + '-' + this.state.phonenumber.substring(3, 7) + '-' + this.state.phonenumber.substring(7, 11)}
          {messageList.moblienumberChangeSetting.M004_mobileNumberChangeSettingText002}<br></br>
          {messageList.moblienumberChangeSetting.M005_mobileNumberChangeSettingText002}</p>
          <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/otp/" className="link-txt _other" target="otherwindow">
            <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.moblienumberChangeSetting.M006_newWindowIcon} className="icon-blank" />{messageList.moblienumberChangeSetting.M007_onetimePassword}</a>

          <div id="JS_error">
            { // エラーメッセージ表示
              (this.state.errorMap && this.state.errorMap.ERROR) &&
              <div className="panel-attention">
                <h2 className="panel-attention_hdg">
                  <span className="panel-attention_icon">
                    <img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.moblienumberChangeSetting.M008_errorIconText} />
                  </span>{messageList.moblienumberChangeSetting.M009_errorBoxTitle}
                </h2>
                <p>{this.state.errorMap.ERROR}</p>
              </div>/* /panel-attention */
            }
          </div>

          <div className="panel-form">
            <div className="panel-form-inner">
              <div className="panel-form-inner_item">
                <form name="dummy" id="JS_jmbForm">

                  <div className="panel-form-inner_parts">
                    <p className="form-label"><label htmlFor="LA_input-code">{messageList.moblienumberChangeSetting.M010_confirmCode}</label></p>
                    <input type="tel" id="LA_input-code" name="authCode"
                    className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('authCode') ? 
                    "form-txt js-form-validate-blank is-error JS_jmbTel is-blank" : "form-txt js-form-validate-blank JS_jmbTel is-blank"} 
                    maxLength="6" aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('authCode') ? "true" : "false"} 
                    value={this.state.authCode} onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress} autoComplete="one-time-code"/>
                    <input type="text" style={boxStyle}/>
                  </div>
                  <div className="btn-wrap">
                    <button type="button" name="__dummy__" className="btn" onClick={this.change}>{messageList.moblienumberChangeSetting.M011_send}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="btn-wrap btn-clm2-l">
            <button type="button" className="btn-back" onClick={this.beforeMobilenumberChange}>{messageList.moblienumberChangeSetting.M012_back}</button>
          </div>

          <div className="hdg-guide">
            <h2 className="hdg-guide_txt">{messageList.moblienumberChangeSetting.M013_notices}</h2>
          </div>
          <ul className="list">
            <li>{messageList.moblienumberChangeSetting.M014_notice001}</li>
          </ul>

        </section>
      );
    }

    // 携帯電話番号登録変更完了画面表示
    else {
      return (
        <section>
          <Helmet
            title={messageList.moblienumberChangeCompletion.M001_browserTitle} >
          </Helmet>

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.moblienumberChangeCompletion.M002_pageTitle}</h1>
          </div>

          <p>{messageList.moblienumberChangeCompletion.M004_guideMessage001}<br></br>
            {messageList.moblienumberChangeCompletion.M005_guideMessage002}
          </p>

          {/* 操作ボタン表示部 */}
          <div className="btn-wrap">
            <button type="submit" name="__dummy__" className="btn-back" onClick={this.beforeOtpChange}>{messageList.moblienumberChangeCompletion.M003_back}</button>
          </div>

        </section>
      );
    }
  }
}

export default MobilenumberChange