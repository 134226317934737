import React from 'react';              

function getDesign(M026_newWindowIcon,M027_parther001,M028_parther002,M029_parther003,
                  M030_parther004,M031_parther005,M032_parther006,M033_parther007) {
  
    return (
        <div>
          <p>{M027_parther001}<br></br>{M028_parther002}<br></br>{M029_parther003}</p>
          <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/security/" className="link-txt _other" target="otherwindow">
            <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={M026_newWindowIcon} className="icon-blank"/>{M030_parther004}
          </a>
          <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/security_world/" className="link-txt _other" target="otherwindow">
            <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={M026_newWindowIcon} className="icon-blank"/>{M031_parther005}
          </a>
          <p>{M032_parther006}</p>
          <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/security_world/gdpr/" className="link-txt _other" target="otherwindow">
            <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={M026_newWindowIcon} className="icon-blank"/>{M033_parther007}
          </a>
        </div>
      );
}

export {getDesign}