import React, {Fragment } from 'react';
import Helmet from 'react-helmet';              

function getDesign(lang) {
    return (
      <Fragment>
        <Helmet>
            <script type="text/javascript">{`
              var rtId = "//js.rtoaster.jp/RTA-b526-91dfccdc03ed/rt.js";
              var host = location.host;
              if (host.match(/^(rgst|prod\.web(1|2|3|4)|mnt(\.web1)?|prod-t(\.sp)?|(fltinfo|jit9|jit6)\.sp5971|(ita|itb)\.domdp|local\.www|mnt-t|web1|stg1\.jmbtour)\.jal\.co\.jp/)){
                  rtId = "//js.rtoaster.jp/RTA-b526-91df77840142/rt.js";
              };
            `}</script>

            <script type="text/javascript">{`!function(r,t,j,s){(j=r.createElement(t)).type="text/javascript",
              j.async=!0,j.charset="utf-8",j.src=rtId,
              (s=r.getElementsByTagName(t)[0]).parentNode.insertBefore(j,s)
              }(document,"script")`
            }</script>

            <script type="text/javascript">{`
            var rt_language = "${lang}"
            `}</script>
        </Helmet>
        <div id="rt_y21_jmbidlink"></div>
      </Fragment>
      
    );
}

export {getDesign}