import React, { Component } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';

const initialState = {
  otpAddressFlg: '0',
  email: '',
  phonenumber: '',
  complete: false,
  errorMap: null,
  loading: false,
  regOtpAddressFlg: '0',
  changeBtnState: true
}

class OtpChange extends Component {
  state = initialState;

  componentDidMount() {

    // GA計測用データレイヤースクリプトを追加する
    window.addDataLayerScript('jmbauth_otp_change_notification');

    // 画面表示処理
    this.drawing();
  }

  drawing = () => {
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    if (!this.state.complete) {
      let result;
      let flag = true;

      // 属性情報取得要求を行う
      try {

        // ローディング開始
        this.setState({ loading: true });

        // 属性情報取得API
        result = axios
          .get(process.env.REACT_APP_URL_CO_SC_API_ATTRIBUTES,
            {
              headers: {
                'accept-language': window.changeLang(sessionStorage.getItem("lang")),
                'Cache-Control': 'no-store',
                'Pragma': 'no-cache'
              }
            }
          )
          .then(response => {
            this.setState({
              errorMap: null, loading: false, otpAddressFlg: response.data.otpAddressFlg,
              email: response.data.email, phonenumber: response.data.phonenumber, regOtpAddressFlg: response.data.otpAddressFlg
            });

          })
          .catch(error => {
            if (error.response) {
              if (error.response.status === 400) {
                this.setState({
                  errorMap:
                    { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.otpChange.M022_errorMessage001 },
                  loading: false,
                  otpAddressFlg: '0',
                  email: '',
                  phonenumber: '',
                  regOtpAddressFlg: '0'
                });
                // エラーメッセージ表示領域に遷移する
                window.location = "#JS_error";
              }
              else if (error.response.status === 401) {
                this.setState({
                  errorMap:
                    { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.otpChange.M022_errorMessage001 },
                  loading: false,
                  otpAddressFlg: '0',
                  email: '',
                  phonenumber: '',
                  regOtpAddressFlg: '0'
                });
                // エラーメッセージ表示領域に遷移する
                window.location = "#JS_error";
              }
              else if (error.response.status === 500) {

                // エラー画面に遷移し、処理を終了する
                flag = false;
                this.props.history.push({
                  pathname: `${process.env.REACT_APP_CONTEXT_PATH}/error`
                });
              }
              else {
                this.setState({
                  errorMap:
                    { ERROR: messageList.otpChange.M022_errorMessage001 },
                  loading: false,
                  otpAddressFlg: '0',
                  email: '',
                  phonenumber: '',
                  regOtpAddressFlg: '0'
                });
                // エラーメッセージ表示領域に遷移する
                window.location = "#JS_error";
              }
            }
            else {
              this.setState({
                errorMap:
                  { ERROR: messageList.otpChange.M022_errorMessage001 },
                loading: false,
                otpAddressFlg: '0',
                email: '',
                phonenumber: '',
                regOtpAddressFlg: '0'
              });
              // エラーメッセージ表示領域に遷移する
              window.location = "#JS_error";
            }
          });

      } catch (err) {
        console.error(err);
        this.setState({
          errorMap:
            { ERROR: messageList.otpChange.M022_errorMessage001 },
          loading: false,
          otpAddressFlg: '0',
          email: '',
          phonenumber: '',
          regOtpAddressFlg: '0'
        });
        // エラーメッセージ表示領域に遷移する
        window.location = "#JS_error";

      } finally {
        Promise.race([result]).finally(()=>{
          if(flag){
            // セッションストレージに登録済みOTP通知用携帯電話番号を設定する
            sessionStorage.setItem('OTPMOBILENUMBER', this.state.phonenumber);
 
            // 通知先ラジオボタンの初期値を設定する
            if (this.state.regOtpAddressFlg === '0') {
              document.getElementById('LA-input-mail').checked = true;
            } else {
              document.getElementById('LA-input-tel').checked = true;
            }
 
            // メールアドレス表示
            document.getElementById('email').innerHTML = (this.state.email === '' ? messageList.otpChange.M024_register : this.state.email);
            // 携帯電話（SMS）表示
            document.getElementById('phonenumber').innerHTML = (this.state.phonenumber === '' ? messageList.otpChange.M024_register : this.state.phonenumber);
 
            if (this.state.email !== '' && this.state.phonenumber !== '') {
              this.setState({changeBtnState : false});
            }
          }
        });
      }

    }

  }

  // OTP通知先変更画面[変更する]ボタン押下処理
  otpNoticesSetting = () => {

    // アクションのレポート作成
    try{
      window.tsPlatform.drs.triggerActionEvent("account_details_change_otpauth").then((actionResponse) => {
        let actionToken = actionResponse.actionToken;
      });
    }catch(e){
    }

    const { otpAddressFlg, regOtpAddressFlg } = this.state;

    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // バリデーションチェックを行う
    if (regOtpAddressFlg === otpAddressFlg) {
      this.setState({ errorMap: { ERROR: messageList.otpChange.M023_errorMessage002 } });
      // エラーメッセージ表示領域に遷移する
      window.location = "#JS_error";
      // 処理を終了する。
      return;
    }

    // 属性情報更新要求を行う
    try {

      // ローディング開始
      this.setState({ loading: true });

      // 属性情報更新API
      axios.put(process.env.REACT_APP_URL_CO_SC_API_ATTRIBUTES_UPDATE,
        {
          attributes : {
           otpAddressFlg : otpAddressFlg
          }
        },
        {
          headers: {
            'accept-language': window.changeLang(sessionStorage.getItem("lang")),
            'Cache-Control': 'no-store',
            'Pragma': 'no-cache'
          }
        }
      )
        .then(response => {

          this.setState({ errorMap: null, complete: true, loading: false });
          // GA計測用データレイヤースクリプトを追加する
          window.addDataLayerScript('jmbauth_otp_change_notification_completion');

          // 画面表示処理
          this.drawing();
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              this.setState({
                errorMap:
                  { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.otpChange.M022_errorMessage001 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location = "#JS_error";
            }
            else if (error.response.status === 401) {
              this.setState({
                errorMap:
                  { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.otpChange.M022_errorMessage001 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location = "#JS_error";
            }
            else if (error.response.status === 500) {

              // エラー画面に遷移し、処理を終了する
              this.props.history.push({
                pathname: `${process.env.REACT_APP_CONTEXT_PATH}/error`
              });
            }
            else {
              this.setState({
                errorMap:
                  { ERROR: messageList.otpChange.M022_errorMessage001 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location = "#JS_error";
            }
          }
          else {
            this.setState({
              errorMap:
                { ERROR: messageList.otpChange.M022_errorMessage001 },
              loading: false
            });
            // エラーメッセージ表示領域に遷移する
            window.location = "#JS_error";
          }
        });

    } catch (err) {
      console.error(err);
      this.setState({
        errorMap:
          { ERROR: messageList.otpChange.M022_errorMessage001 },
        loading: false
      });
      // エラーメッセージ表示領域に遷移する
      window.location = "#JS_error";

    } finally {
    }
  }

  // OTP通知先変更画面へ戻る処理
  beforeOtpChange = () => {
    // 完了フラグ設定
    this.setState({ complete: false,changeBtnState: true }, () => {

      // 画面表示処理
      this.drawing();
    })
  }

  // 画面をクローズする
  close = e => {
    window.open('about:blank', '_self').close();
  }

  radioChange = e => {
    this.setState({ otpAddressFlg: e.currentTarget.value });
  }

  render() {

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // OTP通知先変更完了画面表示
    if (this.state.complete) {
      return (
        <section>
          <Helmet
            title={messageList.otpChangeCompletion.M001_browserTitle} >
          </Helmet>

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.otpChangeCompletion.M002_pageTitle}</h1>
          </div>

          {/* 操作ボタン表示部 */}
          <div className="btn-wrap">
            <button type="submit" name="__dummy__" className="btn-back" onClick={this.beforeOtpChange}>{messageList.otpChangeCompletion.M003_back}</button>
          </div>

        </section>
      );
    }

    // OTP通知先変更画面表示
    else {
      return (
        <section>
          <Helmet
            title={messageList.otpChange.M001_browserTitle} >
          </Helmet>

          { // Loadingモーダル表示
            this.state.loading &&
            <div id="loading" className="loading-modal"><img className="loading-img" src={`${process.env.PUBLIC_URL}/img/load.gif`} alt="loading" /></div>
          }

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.otpChange.M002_pageTitle}</h1>
            <h2 className="hdg-l1_txtSub">{messageList.otpChange.M003_pageTitleSub}</h2>
          </div>

          <p>{messageList.otpChange.M004_guideMessage001}</p>

          <div id="JS_error">
            { // エラーメッセージ表示
              (this.state.errorMap && this.state.errorMap.ERROR) &&
              <div className="panel-attention">
                <h2 className="panel-attention_hdg">
                  <span className="panel-attention_icon">
                    <img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.otpChange.M005_errorIconText} />
                  </span>{messageList.otpChange.M006_errorBoxTitle}
                </h2>
                <p>{this.state.errorMap.ERROR}</p>
              </div>/* /panel-attention */
            }
          </div>

          <div className="panel-form notice-select-wrap">
            <div className="panel-form-inner">
              <form name="dummy" id="JS_jmbForm">
                <fieldset>
                  <div className="hdg-notice">
                    <h3 className="hdg-notice_txt"><legend>{messageList.otpChange.M007_notices}</legend></h3>
                  </div>

                  <div className="panel-form-inner_item">
                    <span className="form-radio">
                      <input type="radio" name="class-radio" id="LA-input-mail" value='0' onClick={this.radioChange} />
                      <label htmlFor="LA-input-mail" className="form-radio_item">{messageList.otpChange.M008_otpNoticesMailaddress}</label>
                    </span>
                    <div className="txt-wrap">
                      <p className="txt-inner-ttl" id='email'></p>
                      <p className="txt-inner-txt">{messageList.otpChange.M009_mailaddressChangeText001}<br></br>
                        {messageList.otpChange.M010_mailaddressChangeText002}</p>
                      <a href="https://sp5971.jal.co.jp/JmbSp/JR/EmailChgPcPre_ja.do" className="link-txt _other pc-disnon" target="otherwindow">
                        <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.otpChange.M012_newWindowIcon} className="icon-blank" />{messageList.otpChange.M011_mailaddressChangeText003}</a>
                      <a href="https://www121.jal.co.jp/JmbWeb/JR/EmailChgBasicPre_ja.do" className="link-txt _other sp-disnon" target="otherwindow">
                        <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.otpChange.M012_newWindowIcon} className="icon-blank" />{messageList.otpChange.M011_mailaddressChangeText003}</a>
                    </div>
                  </div>

                  <div className="panel-form-inner_item">
                    <span className="form-radio">
                      <input type="radio" name="class-radio" id="LA-input-tel" value="1" onClick={this.radioChange} />
                      <label htmlFor="LA-input-tel" className="form-radio_item">{messageList.otpChange.M013_otpNoticesMobileNumber}</label>
                    </span>
                    <div className="txt-wrap">
                      <p className="txt-inner-ttl" id="phonenumber"></p>
                      <p className="txt-inner-txt">{messageList.otpChange.M014_mobileNumberChangeText001}<br></br>
                        {messageList.otpChange.M015_mobileNumberChangeText002}<br></br>
                        {messageList.otpChange.M016_mobileNumberChangeText003}</p>
                      <a href={`${process.env.REACT_APP_CONTEXT_PATH}/mobilenumber_change?design=` + sessionStorage.getItem('design') + "&lang=" + sessionStorage.getItem('lang')} className="link-txt">{messageList.otpChange.M017_mobileNumberChangeText004}</a>
                    </div>
                  </div>
                </fieldset>

                <div className="btn-wrap btn-clm2-l">
                  <div><button type="button" className="btn" onClick={this.otpNoticesSetting} id="otpUpdate" disabled={this.state.changeBtnState}>{messageList.otpChange.M018_update}</button></div>
                  <div><button type="button" className="btn-back" onClick={this.close}>{messageList.otpChange.M019_back}</button></div>
                </div>
              </form>
            </div>
          </div>

          {/* 案内表示部 */}
          <div className="hdg-guide">
            <h2 className="hdg-guide_txt">{messageList.otpChange.M020_notices}</h2>
          </div>
          <ul className="list">
            <li>{messageList.otpChange.M021_notice001}</li>
          </ul>
        </section>
      );
    }
  }
}

export default OtpChange