import React from 'react';              

function getDesign(M012_remPassword) {
  
    return (
        <span className="form-checkbox">
            <input type="checkbox" id="LA_form-checkbox-02" name="__dummy__"/>
            <label htmlFor="LA_form-checkbox-02" className="form-checkbox_item is-blank">{M012_remPassword}</label>
        </span>
    );
}

export {getDesign}