import React, {Component} from "react";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import queryString from 'query-string';
import Helmet from "react-helmet";

//コンポーネント読み込み
import CommonLogin from './CommonLogin';
import Logout from './Logout';
import PwdChange from './PwdChange';
import PwdReset from './PwdReset';
import PinChange from './PinChange';
import Error from './Error';
import SnsRelationSelect from './SnsRelationSelect';
import SnsRelation from './SnsRelation';
import SNSSetting from './SNSSetting';
import OtpChange from './OtpChange';
import MobilenumberChange from './MobilenumberChange';

class Parent extends Component {

  render() {
    let params = {};
    let snsAssociateParams = {};

    // クエリパラメータが存在した場合
    if(Object.keys(this.props.qs).length) {
    
      // 必要なもののみ取得しコンポーネントへ渡す
      let tempParams = {};
      if (this.props.qs.AUTHENTICATED) {
        tempParams = queryString.parse(this.props.qs.AUTHENTICATED.substring(this.props.qs.AUTHENTICATED.indexOf("?") + 1));
      } 
      else {
        tempParams = this.props.qs;
      }

      params = {
        SITE_ID: this.props.qs.SITE_ID,
        AUTH_TYPE: this.props.qs.AUTH_TYPE,
        AUTHENTICATED: this.props.qs.AUTHENTICATED, 
        MESSAGE_AUTH: this.props.qs.MESSAGE_AUTH,
        ERROR_MESSAGE: this.props.qs.ERROR_MESSAGE,
        DESIGN_TYPE: tempParams.design,   
        LANGUAGE_TYPE: tempParams.lang,   
        GUEST_URL: tempParams.guest_url
      }

      // 画面デザイン
      if (params.DESIGN_TYPE) {
        sessionStorage.setItem("design", params.DESIGN_TYPE);
      } else {
        if (!sessionStorage.getItem("design")) {
          if(localStorage.getItem("design")){
            sessionStorage.setItem("design",localStorage.getItem("design"));
            localStorage.removeItem("design");
          }else{
            sessionStorage.setItem("design", process.env.REACT_APP_DESIGN_DEFAULT_VALUE);
          }
        }
      }
      
      // 画面表示言語
      let validLang = ['ja', 'en', 'it', 'de', 'fr', 'es', 'ru', 'zhcn', 'zhtw', 'zhhk', 'ko', 'th', 'vi', 'id'];
      if (params.LANGUAGE_TYPE && validLang.includes(params.LANGUAGE_TYPE)) {
        sessionStorage.setItem("lang", params.LANGUAGE_TYPE);
      } else {
        if (!sessionStorage.getItem("lang")) {
          if(localStorage.getItem("lang")){
            sessionStorage.setItem("lang",localStorage.getItem("lang"));
            localStorage.removeItem("lang");
          }else{
            sessionStorage.setItem("lang", process.env.REACT_APP_LANGUAGE_DEFAULT_VALUE);
          }
        }
      }

      // 画面表示言語により対応する言語ファイルを読み取り、セッションに保存する
      let messageJson = "";
      messageJson = require('../jsonFile/message_' + sessionStorage.getItem("lang") + '.json');
      sessionStorage.setItem("messageList", JSON.stringify(messageJson)); 

      // SNS連携TID
      if ( this.props.qs.SNS_ASSOCIATE_TID )
      {
        snsAssociateParams = {
          SNS_ASSOCIATE_TID: this.props.qs.SNS_ASSOCIATE_TID
        }
        params.SNS_ASSOCIATE_TID = snsAssociateParams.SNS_ASSOCIATE_TID;
        sessionStorage.snsAssociateParams = JSON.stringify(snsAssociateParams);
      }
      else{
        if(sessionStorage.snsAssociateParams){
          snsAssociateParams = JSON.parse(sessionStorage.snsAssociateParams);
          params.SNS_ASSOCIATE_TID = snsAssociateParams.SNS_ASSOCIATE_TID;
        }
      }
    }
    // クエリパラメータが存在しない場合
    else {
      // セッションストレージに画面デザインが存在しない場合
      if (!sessionStorage.getItem("design")) {
        // ローカルストレージに画面デザインが存在する場合
        if(localStorage.getItem("design")){
          sessionStorage.setItem("design",localStorage.getItem("design"));
          localStorage.removeItem("design");
        }else{
          sessionStorage.setItem("design", process.env.REACT_APP_DESIGN_DEFAULT_VALUE);
        }
      }

      // セッションストレージに画面表示言語が存在しない場合
      if (!sessionStorage.getItem("lang")) {
        let messageJson = "";
        // ローカルストレージに画面表示言語が存在する場合
        if(localStorage.getItem("lang")){
          sessionStorage.setItem("lang",localStorage.getItem("lang"));
          localStorage.removeItem("lang");
          messageJson = require('../jsonFile/message_' + sessionStorage.getItem("lang") + '.json');
        }else{
          sessionStorage.setItem("lang", process.env.REACT_APP_LANGUAGE_DEFAULT_VALUE);
          messageJson = require('../jsonFile/message_' + process.env.REACT_APP_LANGUAGE_DEFAULT_VALUE + '.json');
        }
        sessionStorage.setItem("messageList", JSON.stringify(messageJson));
      }

      // SNS連携TID
      if(sessionStorage.snsAssociateParams){
        snsAssociateParams = JSON.parse(sessionStorage.snsAssociateParams);
        params.SNS_ASSOCIATE_TID = snsAssociateParams.SNS_ASSOCIATE_TID;
      }
    }

    return (
      <section className="section">
        <Helmet
          htmlAttributes={{
            lang: window.changeLang(sessionStorage.getItem("lang")),
          }} >  
        </Helmet>
        <Router>
          <Switch>
            <Route path={`${process.env.REACT_APP_CONTEXT_PATH}/login`} render={ (props) => <CommonLogin params= {params} {...props}/> }/>
            <Route path={`${process.env.REACT_APP_CONTEXT_PATH}/logout`} render={ () => <Logout params= {params} /> }/>
            {(process.env.REACT_APP_USE_PWD_CHANGE === "true") && (
              <Route path={`${process.env.REACT_APP_CONTEXT_PATH}/menu/pwd_change`} render={ (props) => <PwdChange params= {params} {...props}/> }/>
            )}
            {(process.env.REACT_APP_USE_PWD_RESET === "true") && (
            <Route path={`${process.env.REACT_APP_CONTEXT_PATH}/pwd_reset`} render={ (props) => <PwdReset params= {params} {...props}/> }/>
            )}
            {(process.env.REACT_APP_USE_PIN_CHANGE === "true") && (
            <Route path={`${process.env.REACT_APP_CONTEXT_PATH}/pin_change`} render={ (props) => <PinChange params= {params} {...props}/> }/>
            )}         
            {(process.env.REACT_APP_USE_SNS_RELATION === "true") && (
              <Route path={`${process.env.REACT_APP_CONTEXT_PATH}/sns_relation_select`} render={ () => <SnsRelationSelect params= {params} /> }/>
            )}
            {(process.env.REACT_APP_USE_SNS_RELATION === "true") && (
              <Route path={`${process.env.REACT_APP_CONTEXT_PATH}/sns_relation`} render={ () => <SnsRelation params= {params} /> }/>
            )}
            {(process.env.REACT_APP_USE_SNS_SETTING === "true") && (
              <Route path={`${process.env.REACT_APP_CONTEXT_PATH}/menu/sns`} render={ () => <SNSSetting params= {params} /> }/>
            )}
            {(process.env.REACT_APP_USE_OTP_CHANGE === "true") && (
              <Route path={`${process.env.REACT_APP_CONTEXT_PATH}/menu/otp_auth`} render={ (props) => <OtpChange params= {params} {...props}/> }/>
            )}
             {(process.env.REACT_APP_USE_MOBILENUMBER_CHANGE === "true") && (
              <Route path={`${process.env.REACT_APP_CONTEXT_PATH}/mobilenumber_change`} render={ (props) => <MobilenumberChange params= {params} {...props}/> }/>
            )}
            <Route path={`${process.env.REACT_APP_CONTEXT_PATH}/error`} render={ (props) => <Error params= {params} {...props}/> }/> 
            <Route component={Error} />
          </Switch>
        </Router>
      </section>
    )
  }
}

export default Parent