import React, {Component} from 'react';
import Helmet from 'react-helmet';

const initialState = {
  otp: ''
}

class SpecificOtpLogin extends Component {
  state = initialState;

  // 初期表示処理
  componentDidMount() {

    if ("OTPCredential" in window) {
      const ac = new AbortController();
      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          this.setState({ otp : otp.code });
        })
        .catch((err) => {
        });
    }
    
    // 「GA計測用データレイヤースクリプト追加処理」を呼出す
    window.addDataLayerScript('jmbauth_specific_service_otp');

  }

  // [ログイン]ボタン押下時処理
  login = () => {
    
    // アクションのレポート作成
    try{
      window.tsPlatform.drs.triggerActionEvent("login_serviceotp").then((actionResponse) => {
        let actionToken = actionResponse.actionToken;
      });
    }catch(e){
    }

    // 親コンポーネントの共通ログインAPI呼出す処理を呼び出す
    // 引数
    const { otp } = this.state;
    const info={ PWD:otp };
    // メソッドを呼出す
    this.props.loginMethod(info);
  }

  // 画面入力項目クリア処理
  componentDidUpdate(prevProps) {

    // 現在のエラーマップは前のエラーマップを比較し、更新された場合、画面入力項目をクリアする
    if (this.props.errMap !== prevProps.errMap) {
      // ワンタイムパスワードをクリアする
      this.setState({ otp: '' });
    }
  }
  
  handleChange = e => {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value.trim() });
  }

  // Enterキー押下時処理
  handleKeyPress = e => {
      if(e.key === "Enter"){
      e.preventDefault();
      this.login();
    }
  }

  render() {

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));
    
    // 画面に表示するメッセージを作成する
    let maskMessage = "";
    if(this.props.maskphonenumber){
      maskMessage = messageList.otpLogin.M016_guideMessage005 + this.props.maskphonenumber + messageList.otpLogin.M017_guideMessage006;
    }else if(this.props.maskmail){
      maskMessage = messageList.otpLogin.M003_guideMessage001 + this.props.maskmail + messageList.otpLogin.M004_guideMessage002;
    }else{
      maskMessage = messageList.otpLogin.M003_guideMessage001 + messageList.otpLogin.M004_guideMessage002;
    }
    const boxStyle = {
      visibility:"hidden",
      width:"1px",
      height:"1px"
    };

    return(
      <section>
        <Helmet
          title={messageList.otpLogin.M001_browserTitle} >
        </Helmet>

        { // Loadingモーダル表示
          this.props.loading &&
          <div id="loading" className="loading-modal"><img className="loading-img" src={`${process.env.PUBLIC_URL}/img/load.gif`} alt="loading"/></div>
        }

        {/* 画面タイトル表示部 */}
        <div className="hdg-l1">
          <h1 className="hdg-l1_txt">{messageList.otpLogin.M002_pageTitle}</h1>
        </div>

        <p>{maskMessage}<br></br>
          {messageList.otpLogin.M005_guideMessage003}</p>
        <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/contact/" className="link-txt _other" target="otherwindow">
          <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.otpLogin.M015_newWindowIcon} className="icon-blank"/>{messageList.otpLogin.M006_guideMessage004}
        </a>

        <div id="JS_error">
        { // エラーメッセージ表示
          (this.props.errMap && this.props.errMap.ERROR) &&              
          <div className="panel-attention">
            <h2 className="panel-attention_hdg">
              <span className="panel-attention_icon">
                <img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.otpLogin.M007_errorIconText}/>
              </span>{messageList.otpLogin.M008_errorBoxTitle}
            </h2>
            <p>{this.props.errMap.ERROR}</p>
          </div>/* /panel-attention */
        }
        </div>

        <div className="panel-form">
          <div className="panel-form-inner">
            <div className="panel-form-inner_item">
              <form name="dummy" id="JS_jmbForm">

                {/* 画面項目表示部 */}
                {/* ワンタイムパスワード */}
                <div className="panel-form-inner_parts">
                  <p className="form-label"><label htmlFor="LA_input-password">{messageList.otpLogin.M009_otpPassword}</label></p>
                  <input type="tel" id="LA_input-password" 
                    className={ this.props.errMap && this.props.errMap.errorId ==='otp' ? "form-txt js-form-validate-blank JS_jmbPass is-blank is-error" : 
                    "form-txt js-form-validate-blank JS_jmbPass is-blank"} 
                    maxLength="6" placeholder="" aria-invalid={ this.props.errMap && this.props.errMap.errorId ==='otp' ? "true" : "false"} 
                    name="otp" value={this.state.otp} onChange={this.handleChange} 
                    onKeyPress={this.handleKeyPress} autoComplete="one-time-code"/>
                  <input type="text" style={boxStyle}/>
                </div>            

                {/* 操作ボタン表示部 */}
                <div className="btn-wrap">
                  <button type="button" name="__dummy__" className="btn" onClick={this.login}>{messageList.otpLogin.M010_login}</button>
                </div>

              </form>
            </div> {/* /panel-form-inner_item */}
          </div> {/* /panel-form-inner */}
        </div>

        {/* 案内表示部 */}
        <div className="hdg-guide">
          <h2 className="hdg-guide_txt">{messageList.otpLogin.M011_notices}</h2>
        </div>
        <ul className="list">          
          <li>{messageList.otpLogin.M012_notice001}</li>
        </ul>
      </section>
    );
  }
}

export default SpecificOtpLogin