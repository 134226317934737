import React, {Component} from 'react';
import Helmet from 'react-helmet';

class Logout extends Component {

  componentDidMount() {

    // GA計測用データレイヤースクリプトを追加する
    window.addDataLayerScript('jmbauth_logout');
  }

  render() {
    setTimeout(() => {
      // ユーザー設定のクリア
      try{
        window.tsPlatform.drs.clearUser();
      }catch(e){
      }
    }, 1000);
    
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    return (
      <section>
        <Helmet
          title={messageList.logout.M001_browserTitle} >
            <script type="text/javascript">{`
              var JALCOJP = Rtoaster.Cookie.get("JALCOJP");
              var memNo = Rtoaster.Cookie.get("memNo");
              var rt_update_cookie = ""
              
              if (JALCOJP && !memNo) {
                rt_update_cookie = Rtoaster.Cookie.get("JALCOJP");
              } else if (!JALCOJP && memNo) {
                rt_update_cookie = Rtoaster.Cookie.get("memNo");
              } else if (JALCOJP && memNo) {
              　rt_update_cookie = Rtoaster.Cookie.get("memNo");
              } else {
                
              };
              
              var rt_data = {
                user_id: rt_update_cookie
              };
            `}</script>

            <script type="text/javascript">{`!function(r,t,j,s){(j=r.createElement(t)).type="text/javascript",
            j.async=!0,j.charset="utf-8",j.src="//js.rtoaster.jp/RTA-b526-91dfccdc03ed/rt.js",
            (s=r.getElementsByTagName(t)[0]).parentNode.insertBefore(j,s)
            }(document,"script")`}</script>
        </Helmet>

        <div className="hdg-l1">
          <h1 className="hdg-l1_txt">{messageList.logout.M002_pageTitle}</h1>
        </div>

        <p>{messageList.logout.M003_message}</p>

        <hr className="hr-l"/>
        <p className="center">{messageList.logout.M004_selectCityLang001}<br className=" pc-disnon"/>{messageList.logout.M005_selectCityLang002}</p>

        <div id="JS_countryLangBox" className="panel-countrylang">
          <form action="" className="">

            <div className="panel-country">
              <div className="form-select _full">
                <select name="" id="JS_countryList">
                </select>
              </div>
            </div>

            <div className="panel-lang" data-content="JS_langSelect">
              <ul className="panel-lang_list">
                <li>
                  <span className="form-radio">
                    <input type="radio" name="class-radio" id="LB-lang-lc" data-lang="lc"/>
                    <label htmlFor="LB-lang-lc" className="form-radio_item"></label>
                  </span>
                </li>
                <li>
                  <span className="form-radio">
                    <input type="radio" name="class-radio" id="LB-lang-jp" data-lang="ja"/>
                    <label htmlFor="LB-lang-jp" className="form-radio_item"></label>
                  </span>
                </li>
                <li>
                  <span className="form-radio">
                    <input type="radio" name="class-radio" id="LB-lang-en" data-lang="en"/>
                    <label htmlFor="LB-lang-en" className="form-radio_item"></label>
                  </span>
                </li>
              </ul>
            </div>
            <div className="btn-wrap">
              <a href="javascript:void(0)" className="btn-back" data-btn="JS_continue">{messageList.logout.M006_home}</a>
            </div>
          
          </form>
        </div>

        {/* Rトースターエリア */}
        <div id="rtoaster"></div>
      </section>
    );
  }
}

export default Logout