import React, {Component, Fragment } from 'react';
import Helmet from 'react-helmet';

const initialState = {
  id: '',
  password: '',
  showPwd: false
}

class Login extends Component {
  state = initialState;

  // 初期処理
  componentDidMount() {

    // 「オートログインチェックボックス」項目が画面に存在する場合、初期表示状態をtrueに設定する。
    if (document.getElementById('LA_form-checkbox-02')) {
      document.getElementById('LA_form-checkbox-02').checked = true;
    }
  }

  // [ログイン]ボタン押下時処理
  login = () => {
    
    // アクションのレポート作成
    try{
      window.tsPlatform.drs.triggerActionEvent("login_idpwd").then((actionResponse) => {
        let actionToken = actionResponse.actionToken;
      });
    }catch(e){
    }

    // 低レベルセッション作成フラグ」変数を定義する
    let lowLevelSessionFlg;

    // 「低レベルセッション作成フラグ」に値を設定する
    if (document.getElementById('LA_form-checkbox-02')) {
      // オートログインチェックボックスの画面入力値を設定する
      lowLevelSessionFlg = document.getElementById('LA_form-checkbox-02').checked;
    }
    else {
      // 親コンポーネントから受け取った「オートログイン状態フラグ」の値を設定する
      lowLevelSessionFlg = this.props.autoLoginStateFlg;
    }
    
    // 親コンポーネントの共通ログインAPI呼出す処理を呼び出す
    // 引数
    const { id, password } = this.state;
    let tempId;
    if (this.props.autoLoginStateFlg) {
      tempId = this.props.autoLoginId;
    }
    else {
      tempId = id;
    }
    const info={ ORG_ID: 'jmbNo', ID:tempId, PWD:password, LowLevelSessionFlg:lowLevelSessionFlg };
    // メソッドを呼出す
    this.props.loginMethod(info);
  }

  // 画面入力項目クリア処理
  componentDidUpdate(prevProps) {

    // 現在のエラーマップは前のエラーマップを比較し、更新された場合、画面入力項目をクリアする
    if (this.props.errMap !== prevProps.errMap) {
      // パスワードをクリアする
      this.setState({ password: '' });
    }
  }

  // [ログアウト]ボタン押下時処理
  logout = () => {
    this.props.logoutMethod();  
  }

  // パスワードマスク表示切替処理
  swhichPwdShow = e => {
    this.setState({ showPwd: e.currentTarget.checked });
  }

  handleChange = e => {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value.trim() });
  }

  // SNSログインボタン押下時処理
  snsLogin = (sns) =>{

    if(sns===0){
      const info = {
        AUTH_TYPE: process.env.REACT_APP_AUTH_TYPE,
        snsid: process.env.REACT_APP_SNSID_GOOGLE
      };
      // セッションストレージにSNSログイン情報を設定する
      sessionStorage.setItem('SNSLOGIN', 'True');
      this.props.snslogin(info);
    }else if(sns===1){
      const info = {
        AUTH_TYPE: process.env.REACT_APP_AUTH_TYPE,
        snsid: process.env.REACT_APP_SNSID_YAHOO
      };
      // セッションストレージにSNSログイン情報を設定する
      sessionStorage.setItem('SNSLOGIN', 'True');
      this.props.snslogin(info);
    }else if(sns===2){
      const info = {
        AUTH_TYPE: process.env.REACT_APP_AUTH_TYPE,
        snsid: process.env.REACT_APP_SNSID_LINE
      };
      // セッションストレージにSNSログイン情報を設定する
      sessionStorage.setItem('SNSLOGIN', 'True');

      // ローカルストレージに画面デザイン、画面表示言語を設定する
      localStorage.setItem('design',sessionStorage.getItem('design'));
      localStorage.setItem('lang',sessionStorage.getItem('lang'));
      this.props.snslogin(info);
    }
  }

  // Enterキー押下時処理
  handleKeyPress = e => {
    if(e.key === "Enter"){
      e.preventDefault();
      this.login();
    }
  }

  // 画面表示処理
  render() {
    
    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // RP別デザインを取得する
    let designI01; // オートログインデザイン
    let designI02; // JALカードデザイン（Rトースター）
    let designI03; // 提携社用デザイン
    let designI04; // SNSログインデザイン
    let designI05; // LINEログイン非表示デザイン
    // デザイン定義ファイルを読み込み
    let designJson = require('../jsonFile/design.json');

    // 画面、項目ID、デザイン値に該当するデザインファイルを読み込む
    Object.keys(designJson.login.I01).forEach(function(data) {
      if (data === sessionStorage.getItem('design')) {
        designI01 = require('./design/' + designJson.login.I01[data]);
      }    
    });
    Object.keys(designJson.login.I02).forEach(function(data) {
      if (data === sessionStorage.getItem('design')) {
        designI02 = require('./design/' + designJson.login.I02[data]);
      }    
    });
    Object.keys(designJson.login.I03).forEach(function(data) {
      if (data === sessionStorage.getItem('design')) {
        designI03 = require('./design/' + designJson.login.I03[data]);
      }    
    });
    designJson.login.I04.forEach(function(data) {
      if (data === sessionStorage.getItem('design')) {
        designI04 = true;
      }    
    });
    designJson.login.I05.forEach(function(data) {
      if (data === sessionStorage.getItem('design')) {
        designI05 = true;
      }    
    });
   
    return(       
      <section>
        {(designI02) ? 
        <Helmet 
          title={messageList.login.M001_browserTitle_jci} >
        </Helmet> : 
        <Helmet
          title={messageList.login.M001_browserTitle} >
        </Helmet>}
       
        { // Loadingモーダル表示
          this.props.loading &&
          <div id="loading" className="loading-modal"><img className="loading-img" src={`${process.env.PUBLIC_URL}/img/load.gif`} alt="loading"/></div>
        }
       
        { // LINEタブ遷移モーダル表示
          this.props.tabChangeFlg &&
          <div id="tabChange" className="tab-change-modal">
            <span className="tab-change-message">
              リダイレクトしています…<br></br>
              終了後は画面を閉じてください<br></br>
              <br></br>
              Redirecting…<br></br>
              Please close this screen when finished.
            </span>
          </div>
        }

        {/* 画面タイトル表示部 */}
        <div className="hdg-l1">
          {(designI02) ? 
          <h1 className="hdg-l1_txt">{messageList.login.M002_pageTitle_jci}</h1> : 
          <h1 className="hdg-l1_txt">{messageList.login.M002_pageTitle}</h1>}
        </div>

        {/* 提携社デザイン表示エリア */}
        {(designI03) && designI03.getDesign(messageList.login.M026_newWindowIcon,messageList.login.M027_parther001,
                  messageList.login.M028_parther002,messageList.login.M029_parther003,messageList.login.M030_parther004,
                  messageList.login.M031_parther005,messageList.login.M032_parther006,messageList.login.M033_parther007)}
        {/* 提携社デザイン表示エリア */}

        <div id="JS_error">
        { // エラーメッセージ表示
          (this.props.errMap && this.props.errMap.ERROR) &&              
          <div className="panel-attention">
            <h2 className="panel-attention_hdg">
              <span className="panel-attention_icon">
                <img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.login.M003_errorIconText}/>
              </span>{messageList.login.M004_errorBoxTitle}
            </h2>
            <p>{this.props.errMap.ERROR}</p>
          </div>/* /panel-attention */
        }
        </div>

        <div className="panel-form">
          <div className={(!this.props.snsHidden && designI04) ? "panel-form-inner dis-fl" : "panel-form-inner"}>
            <div className={(!this.props.snsHidden && designI04) ? "panel-form-inner_item dis-fl-item" : "panel-form-inner_item"}>
              <div className={(!this.props.snsHidden && designI04) ? "dis-fl-item_contents" : ""}>
                <form name="dummy" id="JS_jmbForm">

                  {/* 画面項目表示部 */}
                  {/* お得意様番号 */}
                  <div className="panel-form-inner_parts">                
                    {                 
                      (!this.props.autoLoginStateFlg) &&
                      <Fragment>
                        <p className="form-label"><label htmlFor="LA_input-number-01">{messageList.login.M005_id}</label></p>
                        <input type="tel" id="LA_input-number-01" name="id" 
                          className={ this.props.errMap && this.props.errMap.errorId ==='id' ? "form-txt js-form-validate-blank JS_jmbNo is-blank is-error" : 
                          "form-txt js-form-validate-blank JS_jmbNo is-blank"} 
                          maxLength="9" placeholder="" aria-invalid={ this.props.errMap && this.props.errMap.errorId ==='id' ? "true" : "false"} 
                          value={this.state.id} onChange={this.handleChange}/>
                      </Fragment>
                    }
                    {
                      (this.props.autoLoginStateFlg) &&
                      <Fragment>
                        <p className="form-label"><label htmlFor="LA_input-number-02">{messageList.login.M005_id}</label></p>
                        <input type="tel" id="LA_input-number-02" className="form-txt js-form-validate-blank JS_jmbNo" maxLength="9" 
                          placeholder="" aria-invalid="false" readOnly defaultValue={this.props.autoLoginId} tabIndex="-1"/>
                      </Fragment>
                    }             
                  </div>

                  {/* パスワード */}
                  <div className="panel-form-inner_parts">
                    <p className="form-label"><label htmlFor="LA_input-password">{messageList.login.M006_password}</label></p>
                    <p className="form-table_txt mb-form-label">
                      <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/password/" className="link-txt _other" target="otherwindow">
                        <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.login.M026_newWindowIcon} className="icon-blank"/>
                          {messageList.login.M007_aboutPassword}
                      </a>
                    </p>
                    {/* パスワード変更を促す文言 */}
                    <p className="fc-rd">{messageList.login.M040_passwordReminder001}<br></br>
                      {messageList.login.M041_passwordReminder002}<br></br>
                      {messageList.login.M042_passwordReminder003}</p>
                    <p className="form-table_txt mb-form-label">
                      <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/password-change/" className="link-txt _other" target="otherwindow">
                        <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.login.M026_newWindowIcon} className="icon-blank"/>
                          {messageList.login.M043_passwordReminder004}
                      </a>
                    </p>
                    <input type={ this.state.showPwd ? "text" : "password"} id="LA_input-password" name="password"
                      className={ this.props.errMap && this.props.errMap.errorId === 'password' ? "form-txt js-form-validate-blank JS_jmbPass is-blank is-error" : 
                      "form-txt js-form-validate-blank JS_jmbPass is-blank"} 
                      maxLength="256" placeholder="" aria-invalid={ this.props.errMap && this.props.errMap.errorId === 'password' ? "true" : "false"}
                      value={this.state.password} onChange={this.handleChange}
                      onKeyPress={this.handleKeyPress}/>

                    {/* パスワードを表示する */}
                    <span className="form-checkbox _block">
                      <input type="checkbox" id="LA_form-checkbox-01" name="__dummy__" aria-controls="LA_input-password" onChange={this.swhichPwdShow}/>
                      <label htmlFor="LA_form-checkbox-01" className="form-checkbox_item is-blank">{messageList.login.M008_showPassword}</label>
                    </span>
                  </div>

                  {/* パスワードを初期化する */}
                  <div className="form-box-wrap ">
                    {(messageList.login.M009_resetPasswordGuide) &&  <p>{messageList.login.M009_resetPasswordGuide}</p>}
                    <a href={"./pwd_reset?design=" + sessionStorage.getItem('design') + "&lang=" + sessionStorage.getItem('lang')} className="link-txt _other" target="_blank">
                      <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.login.M026_newWindowIcon} className="icon-blank"/>
                        {messageList.login.M010_resetPassword}
                    </a>
                  </div>

                  {/* 操作ボタン表示部 */}
                  <div className="btn-wrap">
                    <div>
                      <button type="button" name="__dummy__" className="btn" onClick={this.login}>{messageList.login.M011_login}</button>
                    </div>

                    {/* ログイン状態を保持する */}
                    {(designI01 && !this.props.snsHidden) && designI01.getDesign(messageList.login.M012_remPassword)}               
                  </div>

                </form>
              </div>
            </div> {/* /panel-form-inner_item */}

            {/* SNSログイン */}
            {(!this.props.snsHidden && designI04) &&
              <div className="panel-form-inner_item dis-fl-item">
                <div className="dis-fl-item_contents">
                  <p className="title">{messageList.login.M044_snsTitle}</p>	
                  <div className="other-account-btn_wrap">
                    {/* googleログイン */}
                    {(!this.props.webViewFlag) && 
                    <div>
                      <button type="button" onClick={()=> this.snsLogin(0)}>
                        {(sessionStorage.getItem('lang') === "ja") ?
                          <Fragment>
                            <img src={`${process.env.PUBLIC_URL}/img/btn_google_pc.svg`} alt={messageList.login.M045_snsGoogle} className="sp-disnon"/>
                            <img src={`${process.env.PUBLIC_URL}/img/btn_google_sp.svg`} alt={messageList.login.M045_snsGoogle} className="pc-disnon"/>
                          </Fragment> :
                          <Fragment>
                            <img src={`${process.env.PUBLIC_URL}/img/en_btn_google_pc.svg`} alt={messageList.login.M045_snsGoogle} className="sp-disnon"/>
                            <img src={`${process.env.PUBLIC_URL}/img/en_btn_google_sp.svg`} alt={messageList.login.M045_snsGoogle} className="pc-disnon"/>
                          </Fragment>
                        }
                      </button>
                    </div>}
                    {/* yahooログイン */}
                    <div>
                      <button type="button" onClick={()=> this.snsLogin(1)}>
                        {(sessionStorage.getItem('lang') === "ja") ?
                          <Fragment>
                            <img src={`${process.env.PUBLIC_URL}/img/btn_yahoojapan_pc.svg`} alt={messageList.login.M046_snsYahoo} className="sp-disnon"/>
                            <img src={`${process.env.PUBLIC_URL}/img/btn_yahoojapan_sp.svg`} alt={messageList.login.M046_snsYahoo} className="pc-disnon"/>
                          </Fragment> :
                          <Fragment>
                            <img src={`${process.env.PUBLIC_URL}/img/en_btn_yahoojapan_pc.svg`} alt={messageList.login.M046_snsYahoo} className="sp-disnon"/>
                            <img src={`${process.env.PUBLIC_URL}/img/en_btn_yahoojapan_sp.svg`} alt={messageList.login.M046_snsYahoo} className="pc-disnon"/>
                          </Fragment>
                        }
                      </button>
                    </div>
                    {/* LINEログイン */}
                    {(!(this.props.RPFlag || designI05)) &&
                    <div>
                      <button type="button" onClick={()=> this.snsLogin(2)}>
                        {(sessionStorage.getItem('lang') === "ja") ?
                          <Fragment>
                            <img src={`${process.env.PUBLIC_URL}/img/btn_line_pc.svg`} alt={messageList.login.M053_snsLine} className="sp-disnon"/>
                            <img src={`${process.env.PUBLIC_URL}/img/btn_line_sp.svg`} alt={messageList.login.M053_snsLine} className="pc-disnon"/>
                          </Fragment> :
                          <Fragment>
                            <img src={`${process.env.PUBLIC_URL}/img/en_btn_line_pc.svg`} alt={messageList.login.M053_snsLine} className="sp-disnon"/>
                            <img src={`${process.env.PUBLIC_URL}/img/en_btn_line_sp.svg`} alt={messageList.login.M053_snsLine} className="pc-disnon"/>
                          </Fragment>
                        }
                      </button>
                    </div>}
                  </div>
                
                  <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/snsid/" className="link-txt _other mgt-40" target="otherwindow">
                    <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.login.M026_newWindowIcon} className="icon-blank"/>
                      {messageList.login.M047_snsNotice001}
                  </a>
                  <p>{messageList.login.M048_snsNotice002}</p>
                  <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/snsid/policy/" className="link-txt _other" target="otherwindow">
                    <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.login.M026_newWindowIcon} className="icon-blank"/>
                      {messageList.login.M049_snsNotice003}
                  </a>
                </div>
              </div>
            } 
          </div> {/* /panel-form-inner */} 
        </div>
        
        {/* Rトースターエリア */}
        {(designI02) && designI02.getDesign()}
        {/* Rトースターエリア */} 

        {/* リンク表示部 */}
        <div className="hdg-guide">
          <h2 className="hdg-guide_txt">{messageList.login.M013_notices}</h2>
        </div>
        <ul className="list">
          {
            (this.props.autoLoginStateFlg) &&
            <li>{messageList.login.M014_notice001}
              <div>
                <button type="button" name="__dummy__" className="link-txt _block" onClick={this.logout}>{messageList.login.M015_notice002}</button>
              </div>
            </li>
          }
          {
            (this.props.guestUrl) &&
            <li>{messageList.login.M016_notice003}
              <div>
                <a href={this.props.guestUrl} className="link-txt">{messageList.login.M017_notice004}</a>
              </div>
            </li>
          } 
          <li>{messageList.login.M018_notice005}<br></br>
              {messageList.login.M019_notice006}</li>
          <li>{messageList.login.M020_notice007}
              <div>
                <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/number-info/" className="link-txt _other" target="otherwindow">
                  <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.login.M026_newWindowIcon} className="icon-blank"/>
                    {messageList.login.M021_notice008}
                </a>
              </div>
          </li>
          <li>{messageList.login.M022_notice009}</li>
          {(!this.props.snsHidden && designI04) &&
          <li>{messageList.login.M050_notice011}<br></br>{messageList.login.M051_notice012}</li>
          }
        </ul>
      </section>
    );
  }
}

export default Login