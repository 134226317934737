import React, {Component, Fragment } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';

const initialState = {
  id: '',
  birth_d: '',
  birth_m: '',
  birth_y: '',  
  password: '',
  rePassword: '',
  otp: '',
  onetimeTid: '',
  maskmail: '',
  mailCheck: false,
  complete: false,
  errorMap: null,
  loading : false,
  showPwd: false,
  maskphonenumber: ''
}

class PwdReset extends Component {
  state=initialState;

  // 初期表示処理
  componentDidMount() {

    // GA計測用データレイヤースクリプトを追加する
    window.addDataLayerScript('jmbauth_pwd_reset_start');

    // 生年月日表示設定処理を呼出す
    window.addDateControl();

    // 年の初期値をstateに設定する
    this.setState({ birth_y: document.getElementById('birth_y').value });
  }

  // パスワードリセット画面[変更]ボタン押下時処理
  pwdReset = () => {

    // アクションのレポート作成
    try{
      window.tsPlatform.drs.triggerActionEvent("password_reset_reset").then((actionResponse) => {
        let actionToken = actionResponse.actionToken;
      });
    }catch(e){
    }

    const { password, rePassword, otp, onetimeTid } = this.state;

    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // バリデーションチェックを行う
    if (password === '') {
      this.setState({ errorMap: { ERROR: messageList.pwdReset.M020_errorMessage001, errorId: ['password'] }});  
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
      // 処理を終了する。
      return;
    }

    if (password !== rePassword) {
      this.setState({ errorMap: { ERROR: messageList.pwdReset.M022_errorMessage003, errorId: ['password', 'rePassword'] }, password: '', rePassword: ''});
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
      // 処理を終了する。
      return;
    }

    if (otp === '') {
      this.setState({ errorMap: { ERROR: messageList.pwdReset.M021_errorMessage002, errorId: ['otp'] }});
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
      // 処理を終了する。
      return;
    }

    try {
      // ローディング開始
      this.setState({ loading: true });

      // 3Keyパスワード再設定API
      axios
        .put( process.env.REACT_APP_URL_CO_SC_API_PASSWORD_RESET,
          {
          newPassword: password,
          onetimePassword: otp,
          onetimeTid: onetimeTid
          },
          {headers: {'accept-language': window.changeLang(sessionStorage.getItem('lang')),
                     'Cache-Control': 'no-store',
                     'Pragma': 'no-cache'}}
        )
        .then( response => {
          this.setState({ 
                          errorMap: null, 
                          complete: true,
                          loading: false
                        });
          // GA計測用データレイヤースクリプトを追加する
          window.addDataLayerScript('jmbauth_pwd_reset_completion');
        })
        .catch( error => {
          if ( error.response ) {
            if ( error.response.status === 400 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.pwdReset.M023_errorMessage004 },
                loading: false, password: '', rePassword: '', otp: ''
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            else if ( error.response.status === 401 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.pwdReset.M023_errorMessage004 },
                loading: false, password: '', rePassword: '', otp: ''
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            else if ( error.response.status === 403 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.pwdReset.M023_errorMessage004 },
                loading: false, password: '', rePassword: '', otp: ''
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            else if ( error.response.status === 500 ) {

              // システムエラー画面表示
              this.props.history.push({
                pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`
              });
            }
            else {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.pwdReset.M023_errorMessage004 },
                loading: false, password: '', rePassword: '', otp: ''
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
          }
          else {
            this.setState({ errorMap:
              { ERROR: messageList.pwdReset.M023_errorMessage004 },
              loading: false, password: '', rePassword: '', otp: ''
            });
            // エラーメッセージ表示領域に遷移する
            window.location="#JS_error";
          }
        })
    } catch ( err ) {
      console.error(err);
      this.setState({ errorMap:
        { ERROR: messageList.pwdReset.M023_errorMessage004 },
        loading: false, password: '', rePassword: '', otp: ''
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";

    } finally {
    }
  }

  // パスワードリセット開始画面[再設定する]ボタン押下時処理
  mailCheck = () => {

    const { id, birth_y, birth_m, birth_d } = this.state;

    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // バリデーションチェックを行う
    if (id === '') {
      this.setState({ errorMap: { ERROR: messageList.pwdResetStart.M016_errorMessage001, errorId: ['id'] }
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
      // 処理を終了する。
      return;
    }

    if (birth_d === '' || birth_m === '' || birth_y === '') {
      this.setState({ errorMap: { ERROR: messageList.pwdResetStart.M017_errorMessage002, errorId: ['birth_d', 'birth_m', 'birth_y'] }});
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
      // 処理を終了する。
      return;
    }

    try {

      // お得意様番号が7桁の場合は先頭に00でパディングして9桁で送信する
      let requestId;
      if (id && id.length === 7) {
        requestId = id.padStart(9, '00');
      }
      else {
        requestId = id;
      }

      // ローディング開始
      this.setState({ loading: true });

      // 3Keyパスワード再設定メール送信要求API
      axios
        .post( process.env.REACT_APP_URL_CO_SC_API_MAIL_PASSWORD_RESET,
          {
            id: requestId,
            birthday: birth_y + ('0' + birth_m).slice(-2) + ('0' + birth_d).slice(-2),
            onetimeMailType: process.env.REACT_APP_ONE_TIME_MAIL
          },
          {headers: {'accept-language': window.changeLang(sessionStorage.getItem("lang")),
                     'Cache-Control': 'no-store',
                     'Pragma': 'no-cache'}}
        )
      .then( response => {
        if ( response.data.maskmail || response.data.maskphonenumber) {
          this.setState({ errorMap: null,
            onetimeTid: response.data.onetimeTid,
            maskmail: response.data.maskmail,
            mailCheck: true,
            password: '',
            rePassword: '',
            otp: '',
            loading: false,
            maskphonenumber: response.data.maskphonenumber
            });

          // ユーザーの設定
          try{
            window.tsPlatform.drs.setAuthenticatedUser(requestId);
          }catch(e){
          }

          // GA計測用データレイヤースクリプトを追加する
          window.addDataLayerScript('jmbauth_pwd_reset');
        }
        else {
          this.setState({ errorMap:
            { ERROR: messageList.pwdResetStart.M020_errorMessage004 },
            loading: false, birth_d: '', birth_m: '', birth_y: ''
          });
          // エラーメッセージ表示領域に遷移する
          window.location="#JS_error";
        }
      })
        .catch( error => {
          if ( error.response ) {
            if ( error.response.status === 400 ) {

              // 利用者のワンタイムパスワード通知先が登録されていない
              if (error.response.data.errorCode && error.response.data.errorCode === 'CS-API-302-400-0003') {
                this.props.history.push({
                  pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`,
                  state:{errorScreen: 'ADDRESS_UNREGISTERED_PWDRESET'}
                });
              }
              else {
                this.setState({ errorMap:
                  { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.pwdResetStart.M018_errorMessage003 },
                  loading: false, birth_d: '', birth_m: '', birth_y: ''
                });
                // エラーメッセージ表示領域に遷移する
                window.location="#JS_error";
              } 
            }
            else if ( error.response.status === 500 ) {

              // エラー画面に遷移し、処理を終了する
              this.props.history.push({
                pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`
              });
            }
            else {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.pwdResetStart.M018_errorMessage003 },
                loading: false, birth_d: '', birth_m: '', birth_y: ''
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
          }
          else {
            this.setState({ errorMap:
              { ERROR: messageList.pwdResetStart.M018_errorMessage003 },
              loading: false, birth_d: '', birth_m: '', birth_y: ''
            });           
            // エラーメッセージ表示領域に遷移する
            window.location="#JS_error";
          }
        });

    } catch ( err ) {
      console.error(err);
      this.setState({ errorMap:
        { ERROR: messageList.pwdResetStart.M018_errorMessage003 },
        loading: false, birth_d: '', birth_m: '', birth_y: ''
      });     
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";

    } finally {
    }
  }

  // パスワード表示切り替え処理
  swhichPwdShow = e => {
    this.setState({ showPwd: e.currentTarget.checked });
  }

  // 画面をクローズする
  close = e => {
    window.open('about:blank','_self').close();
  }

  // パスワードリセット開始画面へ戻る処理
  beforeMailCheck = () => {
    // メールチェックフラグ設定
    this.setState({ mailCheck: false, errorMap: null }, () => {

      // GA計測用データレイヤースクリプトを追加する
      window.addDataLayerScript('jmbauth_pwd_reset_start');

      // 生年月日表示設定処理を呼出す
      window.addDateControl(); 
      
      // 画面再描画
      this.forceUpdate();
    })
  }

  handleChange = e => {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value.trim() });
  }

  componentDidUpdate(prevProps,prevState) {
    if (prevState.mailCheck !== this.state.mailCheck && this.state.mailCheck){
      if ("OTPCredential" in window) {
        const ac = new AbortController();
        navigator.credentials
          .get({
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            this.setState({ otp : otp.code });
          })
          .catch((err) => {
          });
      }
    }
  }

  render() {

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // パスワードリセット完了画面
    if (this.state.complete){

      // パスワードリセット完了画面表示
      return (
        <section>
          <Helmet
            title={messageList.pwdResetCompletion.M001_browserTitle} >
          </Helmet>

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.pwdResetCompletion.M002_pageTitle}</h1>
          </div>

          {/* 操作ボタン表示部 */}
          <div className="btn-wrap">
            <button type="button" name="__dummy__" className="btn-back" onClick={this.close}>{messageList.pwdResetCompletion.M003_close}</button>
          </div>
        </section>
      );
    }

    // パスワードリセット画面
    else if (this.state.mailCheck) {  

      // 画面に表示するメッセージを作成する
      let maskMessage = "";
      if(this.state.maskphonenumber){
        maskMessage = messageList.pwdReset.M025_guideMessage005 + this.state.maskphonenumber + messageList.pwdReset.M026_guideMessage006;
      }else if(this.state.maskmail){
        maskMessage = messageList.pwdReset.M003_guideMessage001 + this.state.maskmail + messageList.pwdReset.M004_guideMessage002;
      }else{
        maskMessage = messageList.pwdReset.M003_guideMessage001 + messageList.pwdReset.M004_guideMessage002;
      }

      // パスワードリセット画面表示
      return (
        <section>
          <Helmet
            title={messageList.pwdReset.M001_browserTitle} >
          </Helmet>

          { // Loadingモーダル表示
            this.state.loading &&
            <div id="loading" className="loading-modal"><img className="loading-img" src={`${process.env.PUBLIC_URL}/img/load.gif`} alt="loading"/></div>
          }

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.pwdReset.M002_pageTitle}</h1>
          </div>

          {/* ガイドメッセージ表示部 */}
          <p>{maskMessage}<br></br>
            {messageList.pwdReset.M005_guideMessage003}
          </p>
          <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/contact/" className="link-txt _other" target="otherwindow">
            <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.pwdReset.M024_newWindowIcon} className="icon-blank"/>
            {messageList.pwdReset.M006_guideMessage004}
          </a>

          <div id="JS_error">
          { // エラーメッセージ表示
            (this.state.errorMap && this.state.errorMap.ERROR) &&
            <div className="panel-attention">
              <h2 className="panel-attention_hdg">
                <span className="panel-attention_icon">
                  <img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.pwdReset.M007_errorIconText}/>
                </span>{messageList.pwdReset.M008_errorBoxTitle}
              </h2>
              <p>{this.state.errorMap.ERROR}</p>
            </div>/* /panel-attention */
          }
          </div>

          <div className="panel-form">
            <div className="panel-form-inner">
              <div className="panel-form-inner_item">
                <form name="dummy" id="JS_jmbForm">

                  {/* 画面項目表示部 */}             
                  <div className="panel-form-inner_parts">
                    {/* 新パスワード */}
                    <p className="form-label"><label htmlFor="LA_input-password-01">{messageList.pwdReset.M009_newPassword}</label></p>
                    <p>{messageList.pwdReset.M010_newPasswordType}</p>
                    <p className="form-table_txt mb-form-label">
                      <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/password/" className="link-txt _other" target="otherwindow">
                        <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.pwdReset.M024_newWindowIcon} className="icon-blank"/>
                        {messageList.pwdReset.M011_aboutPassword}
                      </a>
                    </p>
                    <input type={ this.state.showPwd ? "text" : "password"} id="LA_input-password-01" 
                      className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('password') ? 
                      "form-txt js-form-validate-blank JS_jmbNo is-blank is-error" : "form-txt js-form-validate-blank JS_jmbNo is-blank"} 
                      maxLength="256" placeholder="" 
                      aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('password') ? "true" : "false"} 
                      name="password" value={this.state.password} onChange={this.handleChange}/>

                    {/* 新パスワードの再入力 */}
                    <p className="form-label"><label htmlFor="LA_input-password-02">{messageList.pwdReset.M012_reNewPassword}</label></p>
                    <input type={ this.state.showPwd ? "text" : "password"} id="LA_input-password-02" 
                      className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('rePassword') ? 
                      "form-txt js-form-validate-blank JS_jmbPass is-blank is-error" : "form-txt js-form-validate-blank JS_jmbPass is-blank"} 
                      maxLength="256" placeholder="" 
                      aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('rePassword') ? "true" : "false"} 
                      name="rePassword" value={this.state.rePassword} onChange={this.handleChange}/>

                    {/* パスワードを表示する */}
                    <span className="form-checkbox _block">
                      <input type="checkbox" id="LA_form-checkbox-01" name="__dummy__" aria-controls="LA_input-password-01 LA_input-password-02" onChange={this.swhichPwdShow}/>
                      <label htmlFor="LA_form-checkbox-01" className="form-checkbox_item is-blank">{messageList.pwdReset.M013_showPassword}</label>
                    </span>
                  </div>

                  {/* ワンタイムパスワード */}
                  <div className="panel-form-inner_parts">
                    <p className="form-label"><label htmlFor="LA_input-password-03">{messageList.pwdReset.M014_otpPassword}</label></p>
                    <input type="tel" id="LA_input-password-03" 
                      className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('otp') ? 
                      "form-txt js-form-validate-blank JS_jmbPass is-blank is-error" : "form-txt js-form-validate-blank JS_jmbPass is-blank"} 
                      maxLength="6" placeholder="" 
                      aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('otp') ? "true" : "false"} 
                      name="otp" value={this.state.otp} onChange={this.handleChange} autoComplete="one-time-code"/>
                  </div>

                  {/* 操作ボタン表示部 */}
                  <div className="btn-wrap">
                    <div className="lyt-btn-line">
                      <button type="button" name="__dummy__" className="btn-back _small" onClick={this.beforeMailCheck}>{messageList.pwdReset.M015_return}</button>
                      <button type="button" name="__dummy__" className="btn _small" onClick={this.pwdReset}>{messageList.pwdReset.M016_update}</button>
                    </div>
                  </div>

                </form>
              </div> {/* /panel-form-inner_item */}
            </div> {/* /panel-form-inner */}
          </div>

          {/* ご案内 */}
          <div className="hdg-guide">
            <h2 className="hdg-guide_txt">{messageList.pwdReset.M017_notices}</h2>
          </div>
          <ul className="list">
            <li>{messageList.pwdReset.M018_notice001}</li>
          </ul>
        </section>
      );
    }

    // パスワードリセット開始画面表示
    else {
      return (
        <section>
          <Helmet
            title={messageList.pwdResetStart.M001_browserTitle} >
          </Helmet>

          { // Loadingモーダル表示
            this.state.loading &&
            <div id="loading" className="loading-modal"><img className="loading-img" src={`${process.env.PUBLIC_URL}/img/load.gif`} alt="loading"/></div>
          }

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.pwdResetStart.M002_pageTitle}</h1>
          </div>

          {/* ガイドメッセージ表示部 */}
          <p>{messageList.pwdResetStart.M003_guideMessage001}</p>
          <ul className={ sessionStorage.getItem("lang") === 'ja'  ? "noteList" : "noteList_star"}><li>{messageList.pwdResetStart.M004_guideMessage002}</li>
          <li>{messageList.pwdResetStart.M019_guideMessage003}</li></ul>

          <div id="JS_error">
          { // エラーメッセージ表示
            (this.state.errorMap && this.state.errorMap.ERROR) &&
            <div className="panel-attention">
              <h2 className="panel-attention_hdg">
                <span className="panel-attention_icon">
                  <img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.pwdResetStart.M005_errorIconText}/>
                </span>{messageList.pwdResetStart.M006_errorBoxTitle}
              </h2>
              <p>{this.state.errorMap.ERROR}</p>
            </div>/* /panel-attention */
          }
          </div>

          <div className="panel-form">
            <div className="panel-form-inner">
              <div className="panel-form-inner_item">
              <form name="dummy" id="JS_jmbForm">

                {/* 画面項目表示部 */}
                {/* お得意様番号 */}
                <div className="panel-form-inner_parts">
                  <p className="form-label"><label htmlFor="LA_input-number-01">{messageList.pwdResetStart.M007_id}</label></p>
                  <input type="tel" id="LA_input-number-01" 
                    className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('id') ? 
                    "form-txt js-form-validate-blank JS_jmbNo is-blank is-error" : "form-txt js-form-validate-blank JS_jmbNo is-blank"} 
                    name="id" value={this.state.id}
                    maxLength="9" placeholder="" 
                    aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('id') ? "true" : "false"} 
                    onChange={this.handleChange} onKeyDown = {e => {if (e.keyCode === 13) e.preventDefault();}}/>
                </div>

                {/* 生年月日 */}
                <div className="panel-form-inner_parts">
                  <p className="form-label"><label htmlFor="LA_input-number-02">{messageList.pwdResetStart.M008_brithday}</label></p>
                  <div className="form-birthday JS_birthdayBox">
                  <div className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('birth_y') ? 
                       "form-select _year is-blank is-error" : "form-select _year is-blank"}>
                    <select className="JS_yearList" name="birth_y" id="birth_y" title={messageList.pwdResetStart.M011_year} 
                      onChange={this.handleChange} value={this.state.birth_y}
                      aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('birth_y') ? "true" : "false"}>
                      <option value="" >{messageList.pwdResetStart.M011_year}</option>
                    </select>                    
                  </div>
                  <div className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('birth_m') ? 
                       "form-select _month is-blank is-error" : "form-select _month is-blank"}>
                    <select className="JS_monthList" name="birth_m" id="birth_m" title={messageList.pwdResetStart.M010_month} 
                      onChange={this.handleChange} value={this.state.birth_m}
                      aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('birth_m') ? "true" : "false"}>
                      <option value="">{messageList.pwdResetStart.M010_month}</option>
                      <option value="1">01</option>
                      <option value="2">02</option>
                      <option value="3">03</option>
                      <option value="4">04</option>
                      <option value="5">05</option>
                      <option value="6">06</option>
                      <option value="7">07</option>
                      <option value="8">08</option>
                      <option value="9">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                  <div className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('birth_d') ? 
                       "form-select _date is-blank is-error" : "form-select _date is-blank"} >
                    <select className="JS_dayList" name="birth_d" id="birth_d" title={messageList.pwdResetStart.M009_day} 
                      onChange={this.handleChange} value={this.state.birth_d} 
                      aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('birth_d') ? "true" : "false"}>
                      <option value="">{messageList.pwdResetStart.M009_day}</option>
                    </select>
                  </div>                  
                </div>
              </div>
                {/* 操作ボタン表示部 */}
                <div className="btn-wrap">
                  <button type="button" name="__dummy__" className="btn" onClick={this.mailCheck}>{messageList.pwdResetStart.M012_reset}</button>
                </div>

                </form>
              </div> {/* /panel-form-inner_item */}
            </div> {/* /panel-form-inner */}
          </div>

          {/* 案内表示部 */}
          <div className="hdg-guide">
            <h2 className="hdg-guide_txt">{messageList.pwdResetStart.M013_notices}</h2>
          </div>
          <ul className="list">           
            <li>{messageList.pwdResetStart.M014_notice001}</li>
          </ul>
        </section>
      );
    }
  }
}

export default PwdReset